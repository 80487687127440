<template>
    <v-row>
        <v-col cols=12>
            <datastore-create-modal />
        </v-col>
        <v-col>
            <datastore-list-table />
        </v-col>
    </v-row>
</template>

<script>
    import DatastoreListTable from './Datastore/Table.vue';
    import DatastoreCreateModal from './Datastore/Create.vue';

    export default {
        name: 'ManageDatastorePage',

        components: {
            DatastoreListTable,
            DatastoreCreateModal,
        },
    };
</script>
