<template>
    <request :request="request" @on-success="onTableRequestSuccess">
        <v-card>
            <v-card-title>Datasets</v-card-title>
            <v-simple-table class="text-left">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Client</th>
                            <th>Datatype</th>
                            <th>Created at</th>
                            <th>Created By</th>
                            <th>Updated at</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in files"
                            :key="item.id"
                        >

                            <td>{{ item.name }}</td>
                            <td>
                                <v-chip
                                    class="ma-2"
                                    color="blue"

                                    small
                                    dark
                                    pill
                                >
                                    <b>{{ item.client.toUpperCase() }}</b>
                                </v-chip>
                            </td>
                            <td>
                                <v-chip
                                    class="ma-2"
                                    color="grey"

                                    small
                                    dark
                                    pill
                                >
                                    <b>{{ item.metadata.datatype.toUpperCase() }}</b>
                                </v-chip>
                            </td>
                            <td>{{ item.metadata.created_at | datetime}}</td>
                            <td>{{ item.metadata.created_by.first_name }} {{  item.metadata.created_by.last_name }}</td>
                            <td>{{ item.metadata.updated_at | datetime}}</td>
                            <td>
                                <v-btn
                                    icon
                                    small
                                    :to="{ name: 'manage-datastore-view', params: { datasetId: item.id }}">
                                    <v-icon small>$view</v-icon>
                                </v-btn>
                                <i-delete-btn
                                    title="Are you sure to delete the report?"
                                    :payload="{datasetId: item.id, datasetName: item.name}"
                                    @on-confirm="deleteDataset"/>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </request>
</template>

<script>
    import bus from '@/ebus';
    import dateutils from '@/utils/dateutils';
    import DatastoreAPI from './webapi';

    export default {
        name: 'DatastoreListTable',

        data: () => ({
            request: () => DatastoreAPI.list(),
            files: [],
        }),

        created() {
            bus.$on('new-dataset-created', () => {
                console.log('EVENT BUS -> Dataset created');
                this.request = () => DatastoreAPI.list();
            });
        },

        methods: {
            onTableRequestSuccess(response) {
                this.files = response.data;
            },
            deleteDataset(payload) {
                const { datasetId, datasetName } = payload;
                DatastoreAPI.deleteDataset(datasetId).then((response) => {
                    console.log(response);
                    if (response.status.success === true) {
                        this.$helpers.notifySuccess(`Dataset '${datasetName}' was deleted.`);
                        this.request = () => DatastoreAPI.list();
                    } else {
                        this.$helpers.notifyError(`Dataset was not deleted. Error: ${response.status.reason}.`);
                    }
                });
            },
        },

        filters: {
            datetime: dateutils.datetime,
        },
    };
</script>
